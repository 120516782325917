import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import * as Breakpoints from "lattice/src/breakpoints.module.scss";
import SEO from "../components/functional/seo";

function DesignBreakpoints() {
  return (
    <div className="design__content">
      <SEO title="Design System - Breakpoints" />
      <h2>Grid Breakpoints</h2>
      <p className="design__text p2">
        Below are the pixel values for all of the grid sizes for each
        breakpoint:
      </p>

      <div className="design__stories design__stories--compact">
        <div className="design__story-section">
          <h5>Mobile Small</h5>

          <p className="design__text p2 type-sp-none">
            Breakpoint Width: {parseInt(Breakpoints.gridMobileSmall, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Inner Grid Size: {parseInt(Breakpoints.gridMobileSmallMain, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Column Size: {parseInt(Breakpoints.gridMobileSmallCol, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Gap Between Columns: {parseInt(Breakpoints.gridMobileSmallGap, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Outside Gutter Size:{" "}
            {parseInt(Breakpoints.gridMobileSmallGutter, 10)} pixels
          </p>
        </div>

        <div className="design__story-section">
          <h5>Mobile Medium</h5>

          <p className="design__text p2 type-sp-none">
            Breakpoint Width: {parseInt(Breakpoints.gridMobileMedium, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Inner Grid Size: {parseInt(Breakpoints.gridMobileMediumMain, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Column Size: {parseInt(Breakpoints.gridMobileMediumCol, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Gap Between Columns: {parseInt(Breakpoints.gridMobileMediumGap, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Outside Gutter Size:{" "}
            {parseInt(Breakpoints.gridMobileMediumGutter, 10)} pixels
          </p>
        </div>

        <div className="design__story-section">
          <h5>Mobile Large</h5>

          <p className="design__text p2 type-sp-none">
            Breakpoint Width: {parseInt(Breakpoints.gridMobileLarge, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Inner Grid Size: {parseInt(Breakpoints.gridMobileLargeMain, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Column Size: {parseInt(Breakpoints.gridMobileLargeCol, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Gap Between Columns: {parseInt(Breakpoints.gridMobileLargeGap, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Outside Gutter Size:{" "}
            {parseInt(Breakpoints.gridMobileLargeGutter, 10)} pixels
          </p>
        </div>

        <div className="design__story-section">
          <h5>Tablet</h5>

          <p className="design__text p2 type-sp-none">
            Breakpoint Width: {parseInt(Breakpoints.gridTablet, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Inner Grid Size: {parseInt(Breakpoints.gridTabletMain, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Column Size: {parseInt(Breakpoints.gridTabletCol, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Gap Between Columns: {parseInt(Breakpoints.gridTabletGap, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Outside Gutter Size: {parseInt(Breakpoints.gridTabletGutter, 10)}{" "}
            pixels
          </p>
        </div>

        <div className="design__story-section">
          <h5>Desktop</h5>

          <p className="design__text p2 type-sp-none">
            Breakpoint Width: {parseInt(Breakpoints.gridDesktop, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Inner Grid Size: {parseInt(Breakpoints.gridDesktopMain, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Column Size: {parseInt(Breakpoints.gridDesktopCol, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Gap Between Columns: {parseInt(Breakpoints.gridDesktopGap, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Outside Gutter Size: {parseInt(Breakpoints.gridDesktopGutter, 10)}{" "}
            pixels
          </p>
        </div>

        <div className="design__story-section">
          <h5>Desktop HD</h5>

          <p className="design__text p2 type-sp-none">
            Breakpoint Width: {parseInt(Breakpoints.gridDesktopHd, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Inner Grid Size: {parseInt(Breakpoints.gridDesktopHdMain, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Column Size: {parseInt(Breakpoints.gridDesktopHdCol, 10)} pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Gap Between Columns: {parseInt(Breakpoints.gridDesktopHdGap, 10)}{" "}
            pixels
          </p>
          <p className="design__text p2 type-sp-none">
            Outside Gutter Size: {parseInt(Breakpoints.gridDesktopHdGutter, 10)}{" "}
            pixels
          </p>
        </div>
      </div>
    </div>
  );
}

export default DesignBreakpoints;
